import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const Frontend = () => {
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	const handleClose = () => setShowErrorMessage(false);

	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-dark">
				<div className="container-fluid">
					<a className="navbar-brand d-lg-none" href="#">
						<img src="images/logo.png" />
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto align-items-center">
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									aria-current="page"
									href="#awards"
								>
									Story
								</a>
							</li>
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									href="#collective"
								>
									Founder's Collective
								</a>
							</li>
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									href="#utility"
								>
									Utility
								</a>
							</li>
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									href="#events"
								>
									Events
								</a>
							</li>
							<li className="nav-item mx-3 d-none d-lg-block">
								<a
									className="navbar-brand page-scroll"
									href="#home"
								>
									<img src="images/logo.png" />
								</a>
							</li>
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									href="#roadmap"
								>
									Roadmap
								</a>
							</li>
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									href="#partners"
								>
									Partners
								</a>
							</li>
							<li className="nav-item mx-3">
								<a
									className="nav-link page-scroll"
									href="#faq"
								>
									FAQ
								</a>
							</li>
							<li className="nav-item mx-3">
								<div className="social-icons-container">
									<div className="mb-3 d-block d-md-none">Orient Yourself.</div>
									<div className="social-icons">
										<a
											href="https://www.facebook.com/theorientalistspirits"
											target="_blank"
										>
											<img src="images/icon-fb.png" />
										</a>
										<a
											href="https://www.instagram.com/orientalistspirits/"
											target="_blank"
										>
											<img src="images/icon-ig.png" />
										</a>
										<a href="#" data-bs-toggle="tooltip" title="Coming Soon!" onClick={() => false}>
											<img src="images/icon-opensea.png" />
										</a>
										<a
											href="https://twitter.com/orientalist_xyz"
											target="_blank"
										>
											<img src="images/icon-twitter.png" />
										</a>
									</div>
									<div className="mt-3 d-block d-md-none">
										theorientalistspirits <span className="highlighted">2022</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<Outlet />

			<footer>
				<div className="container">
					<div className="row mb-5">
						<div className="col-auto m-auto text-center">
							<div className="mb-3">
								<img className="footer-logo" src="images/logo-footer.png" alt="The Orientalist" />
							</div>
							<p className="mb-3">Orient Yourself.</p>
							<div className="social-icons mb-3">
								<a href="https://www.facebook.com/theorientalistspirits" target="_blank">
									<img src="images/icon-fb.png" />
								</a>
								<a href="https://www.instagram.com/orientalistspirits/" target="_blank">
									<img src="images/icon-ig.png" />
								</a>
								<a href="#" data-bs-toggle="tooltip" title="Coming Soon!" onClick={() => false}>
									<img src="images/icon-opensea.png" />
								</a>
								<a href="https://twitter.com/orientalist_xyz" target="_blank">
									<img src="images/icon-twitter.png" />
								</a>
							</div>
							<p className="mb-3">
								theorientalistspirits <span className="highlighted">2022</span>
							</p>
						</div>
					</div>
					<div className="row mb-5 gx-md-5 justify-content-center">
						<div className="col-auto">Privacy Policy</div>
						<div className="col-auto">Terms &amp; Conditions</div>
					</div>
					<div className="text-center disclaimer">
						<small>
							Disclaimer
							<br />* Information shared on this website is not intended to be and does not constitute
							financial advice, investment advice, trading advice, or any other advice. The Orientalist
							Spirits encourages you to do your own research before making any purchases.
						</small>
					</div>
				</div>
			</footer>

			<Modal show={showErrorMessage} centered backdrop="static" onHide={handleClose}>
				<Modal.Header closeButton>Error!</Modal.Header>
				<Modal.Body>
					<p>Sorry, we are unable to preview your wallet.</p>
					<p>Please access your wallet through your wallet provider (for ex. MetaMask).</p>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Frontend;
