export default () => (
	<div id="utility-block">
		<div className="container">
			<div className="row gx-0">
				<div className="col-12 text-center">
					<h1>UTILITY</h1>
					<p>
						Below are the list of <strong>perks exclusive to Founder's Collective,</strong> and with more to
						come.
					</p>
				</div>
			</div>
			<div className="row gx-0">
				<div className="col-12">
					<div id="carouselIndicators" className="carousel slide" data-bs-ride="carousel">
						<div className="carousel-indicators">
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="0"
								className="active"
								aria-current="true"
								aria-label="Slide 1"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="1"
								aria-label="Slide 2"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="2"
								aria-label="Slide 3"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="3"
								aria-label="Slide 4"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="4"
								aria-label="Slide 5"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="5"
								aria-label="Slide 6"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="6"
								aria-label="Slide 7"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="7"
								aria-label="Slide 8"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="8"
								aria-label="Slide 9"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="9"
								aria-label="Slide 10"
							></button>
							<button
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide-to="10"
								aria-label="Slide 11"
							></button>
						</div>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src="images/utility-slides/1.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/2.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/3.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/4.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/5.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/6.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/7.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/8.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/9.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/10.jpg" className="d-block w-100" />
							</div>
							<div className="carousel-item">
								<img src="images/utility-slides/11.jpg" className="d-block w-100" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
