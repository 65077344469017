export default () => (
	<div id="events-block">
		<div className="row gx-0">
			<div className="col-12 col-md-7">
				<div className="content tabular">
					<h1>
						<span className="block">The Founders Collective</span>
						<span className="thin">2022 Global Gathering</span>
					</h1>

					<div className="row gx-0 heading">
						<div className="col-3 col-md-4">Date</div>
						<div className="col-3 col-md-4">City</div>
						<div className="col-6 col-md-4">Venue</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Fri, 30 SEP</div>
						<div className="col-3 col-md-4">Singapore</div>
						<div className="col-6 col-md-4">The Orientalist Tasting Room</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Fri, 07 OCT</div>
						<div className="col-3 col-md-4">Kuala Lumpur</div>
						<div className="col-6 col-md-4">Private Venue</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Tue, 11 OCT</div>
						<div className="col-3 col-md-4">Berlin</div>
						<div className="col-6 col-md-4">Eins44</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Thu. 13 OCT</div>
						<div className="col-3 col-md-4">Paris</div>
						<div className="col-6 col-md-4">To Be Announced</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Sat, 15 OCT</div>
						<div className="col-3 col-md-4">London</div>
						<div className="col-6 col-md-4">Fox Fine Wines</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Sat, 16 OCT</div>
						<div className="col-3 col-md-4">London</div>
						<div className="col-6 col-md-4">Tattu</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Fri, 28 OCT</div>
						<div className="col-3 col-md-4">Bangkok</div>
						<div className="col-6 col-md-4">To Be Announced</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Tue, 22 NOV</div>
						<div className="col-3 col-md-4">Los Angeles</div>
						<div className="col-6 col-md-4">To Be Announced</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Fri, 25 NOV</div>
						<div className="col-3 col-md-4">San Francisco</div>
						<div className="col-6 col-md-4">La Mar Cebicheria Peruana</div>
					</div>
					<div className="row gx-0">
						<div className="col-3 col-md-4">Sat, 03 DEC</div>
						<div className="col-3 col-md-4">Seoul</div>
						<div className="col-6 col-md-4">Terrace Room</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
