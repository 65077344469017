import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
//import { ThirdwebProvider, ChainId } from "@thirdweb-dev/react";
//import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import DrawerProvider from "./providers/DrawerProvider";

//const desiredChainId = ChainId.Mumbai;

//const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
	<React.StrictMode>
		<DrawerProvider>
			{/* <ThirdwebProvider desiredChainId={desiredChainId}> */}
			<Router>
				<App />
			</Router>
			{/* </ThirdwebProvider> */}
		</DrawerProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// vesting contract: 0xCbd06E680b8169116269aA28BB1f8F611dbd2C09
// token contract: 0xE57d1E9B045CB91F5F073A897ca1f445a187Ca5c
