import React, { useEffect, useState, createContext } from "react";

export const DrawerDefaultData = {
	drawerOpen: false,
};

export const DrawerContext = createContext(DrawerDefaultData);

const DrawerProvider = props => {
	const [open, setOpen] = useState(false);
	const [drawerURL, setDrawerURL] = useState(null);

	const openDrawer = selectedType => {
		if (selectedType !== "goldNFT") return;

		setOpen(true);

		// ToDo:: Load this from config
		const url =
			selectedType === "goldNFT"
				? "https://paper.xyz/checkout/62d3f289-1000-44d6-9a2b-b2d5f594ca7b?display=DRAWER"
				: "https://paper.xyz/checkout/62d3f289-1000-44d6-9a2b-b2d5f594ca7b?display=DRAWER";

		setDrawerURL(url);
	};

	const closeDrawer = () => {
		setOpen(false);
	};

	return (
		<DrawerContext.Provider value={{ open, drawerURL, openDrawer, closeDrawer }}>
			{props.children}
		</DrawerContext.Provider>
	);
};

export default DrawerProvider;
