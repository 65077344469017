import { useRef, useState, useContext } from "react";
import NFTStack from "src/componets/NFTStack";
import { DrawerContext } from "src/providers/DrawerProvider";
import Countdown from "../CountdownTimer";

const CollectiveBlock = () => {
	const selectedNFT = useRef(null);

	const { openDrawer } = useContext(DrawerContext);

	const handleNFTSelection = selectedType => {
		openDrawer(selectedType);
		selectedNFT.current = selectedType;
	};

	return (
		<>
			<div id="collective-block">
				<div className="container-fluid gx-0">
					<div className="row gx-0 align-items-center">
						<div className="col-12 col-md-5 float-end"></div>
						<div className="col-12 col-md-7">
							<div className="content">
								<div className="mobile-background-block">
									<h1>
										<span className="block">THE FOUNDER'S</span> COLLECTIVE PASS
									</h1>
									<h3>
										<span className="block">DROPPING OCTOBER 2022. TO GET</span> WHITELISTED{" "}
										<a
											href="https://www.premint.xyz/founders-collective-allowlist/"
											target="_blank"
										>
											CLICK HERE
										</a>
									</h3>
								</div>
								<div className="mobile-regular-block">
									<p>
										<strong>3,000 global members</strong> will have the opportunity to join The
										Orientalist Spirits on its journey to create one of the most innovative spirit
										houses in the world.
									</p>
									<p>
										<strong>As a world-first,</strong> the NFT membership will not only provide the
										community with unparalleled
										<strong>
											access to behind the scenes information and participation in decision
											making,
										</strong>
										but also be <strong>backed by aged whisky</strong> to provide NFT holders with
										<strong>real value from day 1.</strong>
									</p>

									<div className="row align-items-center">
										<div className="col-5">
											<h3>Got Whitelisted? Click on the pass to buy now.</h3>
											<div className="browse-time-left">
												<Countdown date={1667787600000} />
											</div>
										</div>
										<div className="col-7 the-nfts">
											<NFTStack onClick={handleNFTSelection} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CollectiveBlock;
