const HeaderBlock = () => (
	<>
		<div id="home-block">
			<div className="container-fluid gx-0">
				<div className="row gx-0 align-items-center">
					<div className="col-12 col-md-7">
						<div className="content">
							<h1>
								<span className="block">
									<span className="thin">Be A Part Of Our</span>
								</span>
								<span className="block">
									Global Journey <span className="thin">With The</span>
								</span>{" "}
								Founders Collective Pass
							</h1>
							<h3>
								For early access to the private tier,{" "}
								<a href="https://www.premint.xyz/founders-collective-allowlist/" target="_blank">
									click here
								</a>
							</h3>
							<div className="bullet-points">
								<div className="row">
									<div className="col-12 col-md-6">
										<ul>
											<li>VALUE BACKED BY AGED WHISKY</li>
											<li>LIMITED EDITION WHISKY BOTTLE</li>
											<li>FOUNDERS ONLY MONTHLY EVENTS</li>
										</ul>
									</div>
									<div className="col-12 col-md-6">
										<ul>
											<li>PREFERENTIAL ACCESS TO SPIRITS</li>
											<li>COMMUNITY OF TASTEMAKERS</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="social-icons">
								<a href="https://www.facebook.com/theorientalistspirits" target="_blank">
									<img src="images/icon-fb.png" />
								</a>
								<a href="https://www.instagram.com/orientalistspirits/" target="_blank">
									<img src="images/icon-ig.png" />
								</a>
								<a href="#" data-bs-toggle="tooltip" title="Coming Soon!" onClick={() => false}>
									<img src="images/icon-opensea.png" />
								</a>
								<a href="https://twitter.com/orientalist_xyz" target="_blank">
									<img src="images/icon-twitter.png" />
								</a>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-5 float-end">
						<img src="images/bottle.webp" />
					</div>
				</div>
			</div>
		</div>
		<div className="container-fluid media-logos-container">
			<div className="media-logos">
				<div className="row gx-0 align-items-center">
					<div className="col-4 col-md-2">As seen on</div>
					<div className="col-8 col-md-10">
						<div style={{ overflow: "hidden" }}>
							<div className="row gx-2 align-items-center img-ticker" style={{ flexWrap: "nowrap" }}>
								<div className="col-auto col-md-2">
									<img src="images/logo-business-spirit.png" />
								</div>
								<div className="col-auto col-md-2">
									<img src="images/logo-about-time.png" />
								</div>
								<div className="col-auto col-md-2">
									<img src="images/logo-wsta.png" />
								</div>
								<div className="col-auto col-md-2">
									<img src="images/logo-spill.png" />
								</div>
								<div className="col-auto col-md-2">
									<img src="images/logo-robbreport.png" />
								</div>

								<div className="col-auto col-md-2 d-block d-md-none">
									<img src="images/logo-business-spirit.png" />
								</div>
								<div className="col-auto col-md-2 d-block d-md-none">
									<img src="images/logo-about-time.png" />
								</div>
								<div className="col-auto col-md-2 d-block d-md-none">
									<img src="images/logo-wsta.png" />
								</div>
								<div className="col-auto col-md-2 d-block d-md-none">
									<img src="images/logo-spill.png" />
								</div>
								<div className="col-auto col-md-2 d-block d-md-none">
									<img src="images/logo-robbreport.png" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
);

export default HeaderBlock;
