export default () => (
	<div id="growth-block">
		<div>
			<div className="container">
				<div className="row gy-5 justify-content-between align-items-center">
					<div className="col-12 col-md-5 text-center text-xs-left">
						<h1>582%</h1>
						<h3>
							Whisky has proven to be the
							<span className="bold">best performing alternative investment</span> over the decade
							<span className="bold">increasing by a huge 582%</span> in the past decade Knight Frank
							Wealth Report
						</h3>
						<p>Knight Frank Wealth Report</p>
					</div>
					<div className="col-12 col-md-5 text-center text-xs-right">
						<h1>250%</h1>
						<h3>
							Average returns on exit for new make whisky and it has been
							<span className="bold">proven to outperform the stock market and property market</span>
						</h3>
						<p>from Scotch Whisky Industry Review</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);
