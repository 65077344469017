export default () => (
	<div id="faq-block">
		<div>
			<div className="container">
				<h1>FAQ</h1>
				<div className="accordion accordion-flush" id="faqAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingOne">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseOne"
								aria-expanded="true"
								aria-controls="collapseOne"
							>
								What is an NFT?
							</button>
						</h2>
						<div
							id="collapseOne"
							className="accordion-collapse collapse"
							aria-labelledby="headingOne"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								An NFT (Non-fungible Token) is a digital asset that represents real-world objects like
								art, music, memberships, in-game items and videos. They are bought and sold online,
								usually with cryptocurrency and are encoded with the same underlying software. NFTs are
								one of a kind, and have unique identifying codes. Some NFTs collectables and PFPs, while
								others offer utility such as exclusive access to communities, real-world perks, or
								participation in events.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTwo">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTwo"
								aria-expanded="false"
								aria-controls="collapseTwo"
							>
								Where can I buy the NFT?
							</button>
						</h2>
						<div
							id="collapseTwo"
							className="accordion-collapse collapse"
							aria-labelledby="headingTwo"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								During private and public sale, you can mint the NFT on our website. Afterwards, you can
								purchase our NFT on the OpenSea secondary market.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingThree">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseThree"
								aria-expanded="false"
								aria-controls="collapseThree"
							>
								When will this be launched?
							</button>
						</h2>
						<div
							id="collapseThree"
							className="accordion-collapse collapse"
							aria-labelledby="headingThree"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								NFTs will go on sale early October 2022 with an announcement of the date finalised by
								27th September 2022
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingFour">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseFour"
								aria-expanded="false"
								aria-controls="collapseFour"
							>
								How many NFTs are available?
							</button>
						</h2>
						<div
							id="collapseFour"
							className="accordion-collapse collapse"
							aria-labelledby="headingFour"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								There will be 1,000 made available for private buyers who take part in securing their
								spot early, and 2,000 will be available to the public after that.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingFive">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseFive"
								aria-expanded="false"
								aria-controls="collapseFive"
							>
								What blockchain is this project on?
							</button>
						</h2>
						<div
							id="collapseFive"
							className="accordion-collapse collapse"
							aria-labelledby="headingFive"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">Ethereum</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingSix">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseSix"
								aria-expanded="false"
								aria-controls="collapseSix"
							>
								What utility will I receive from this NFT?
							</button>
						</h2>
						<div
							id="collapseSix"
							className="accordion-collapse collapse"
							aria-labelledby="headingSix"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								See above section about our{" "}
								<a href="#utility" className="page-scroll">
									NFT
								</a>
								.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingSeven">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseSeven"
								aria-expanded="false"
								aria-controls="collapseSeven"
							>
								What happens after I claim the bottle of 8 year old Dragon Whisky and open it?
							</button>
						</h2>
						<div
							id="collapseSeven"
							className="accordion-collapse collapse"
							aria-labelledby="headingSeven"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								We hope you enjoyed an exquisite drink. However, once the bottle has been claimed, it
								will alter the Founders Collective Pass NFT. The Orientalist Founders Collective Pass
								NFT will then become a “membership only” edition which still allows you or the next
								holder all the benefits that the Founders Collective Pass has to offer. The only
								difference is that the bottle will be gone forever.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingEight">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseEight"
								aria-expanded="false"
								aria-controls="collapseEight"
							>
								Who's the team behind The Orientalist Founders Collective Pass?
							</button>
						</h2>
						<div
							id="collapseEight"
							className="accordion-collapse collapse"
							aria-labelledby="headingEight"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								This project is launched by The Orientalist Spirits, founded by Michel Lu.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingNine">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseNine"
								aria-expanded="false"
								aria-controls="collapseNine"
							>
								What token standard is The Orientalist Founders Collective contract?
							</button>
						</h2>
						<div
							id="collapseNine"
							className="accordion-collapse collapse"
							aria-labelledby="headingNine"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">ERC-721</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTen">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTen"
								aria-expanded="false"
								aria-controls="collapseTen"
							>
								How do I get whitelisted for this project?
							</button>
						</h2>
						<div
							id="collapseTen"
							className="accordion-collapse collapse"
							aria-labelledby="headingTen"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								There will be many opportunities to enter whitelist raffles through our various
								partnerships and events. Stay tuned to our Twitter for updates.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingEleven">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseEleven"
								aria-expanded="false"
								aria-controls="collapseEleven"
							>
								How do I get the actual bottle?
							</button>
						</h2>
						<div
							id="collapseEleven"
							className="accordion-collapse collapse"
							aria-labelledby="headingEleven"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								If you own the Orientalist Founders Collective Pass, you will need to be in full
								compliance of your jurisdiction to receive the bottle. You will need to submit your
								mailing address so we can ship your bottle safely using priority and insured mail.
								However, this will be subject to your country's jurisdiction and laws on alcohol
								imports.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTwelve">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTwelve"
								aria-expanded="false"
								aria-controls="collapseTwelve"
							>
								How do I access my Orientalist Founders Collective Pass?
							</button>
						</h2>
						<div
							id="collapseTwelve"
							className="accordion-collapse collapse"
							aria-labelledby="headingTwelve"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								You can see your Orientalist Founders Collective Pass by logging into your account on
								our website.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingThirteen">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseThirteen"
								aria-expanded="false"
								aria-controls="collapseThirteen"
							>
								How do I check if I'm on the whitelist?
							</button>
						</h2>
						<div
							id="collapseThirteen"
							className="accordion-collapse collapse"
							aria-labelledby="headingThirteen"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								If you're whitelisted for the NFT, you should receive an email inviting you to purchase
								the NFT. Otherwise, stay tuned for the public sale to purchase one.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
