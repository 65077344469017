import { useState } from "react";
import { useContext } from "react";
import { useRoutes } from "react-router-dom";
import routes from "src/routes";
import "./App.css";
import Drawer from "./componets/Drawer";
import Loading from "./componets/Loading";
import { DrawerContext } from "./providers/DrawerProvider";

function App() {
	let element = useRoutes(routes);
	const { open, drawerURL, closeDrawer } = useContext(DrawerContext);
	const [iframeLoaded, setIframeLoaded] = useState(false);

	const loadComplete = () => {
		setIframeLoaded(true);
	};

	const handleClose = () => {
		setIframeLoaded(false);
		closeDrawer();
	};

	return (
		<div className="App">
			{element}
			<Drawer show={open} onClose={handleClose}>
				<iframe
					style={{ width: "100%", height: "calc(100% - 10px)", border: "none" }}
					src={drawerURL}
					scrolling="no"
					onLoad={loadComplete}
				/>
				{!iframeLoaded && (
					<div className="iframePreloader">
						<Loading />
					</div>
				)}
			</Drawer>
		</div>
	);
}

export default App;
