import AwardBlock from "src/componets/home-blocks/AwardBlock";
import CollectiveBlock from "src/componets/home-blocks/CollectiveBlock";
import ContactBlock from "src/componets/home-blocks/ContactBlock";
import EventsBlock from "src/componets/home-blocks/EventsBlock";
import FaqBlock from "src/componets/home-blocks/FaqBlock";
import GrowthBlock from "src/componets/home-blocks/GrowthBlock";
import HeaderBlock from "src/componets/home-blocks/HeaderBlock";
import PatternsBlock from "src/componets/home-blocks/PatternsBlock";
import RoadmapBlock from "src/componets/home-blocks/RoadmapBlock";
import UtilityBlock from "src/componets/home-blocks/UtilityBlock";

const Home = () => {
	return (
		<>
			<HeaderBlock />
			<AwardBlock />
			<CollectiveBlock />
			<UtilityBlock />
			<EventsBlock />
			<GrowthBlock />
			<RoadmapBlock />
			<PatternsBlock />
			<FaqBlock />
			<ContactBlock />
		</>
	);
};
export default Home;
