export default () => (
	<div id="roadmap-block">
		<div>
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<h1>Roadmap</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-12 d-none d-md-block">
						<img src="images/roadmap-desktop-slide.jpg" alt="roadmap" />
					</div>
					<div className="col-12 d-block d-md-none">
						<div id="carouselRoadmapIndicators" className="carousel slide" data-bs-ride="carousel">
							<div className="carousel-indicators">
								<button
									type="button"
									data-bs-target="#carouselRoadmapIndicators"
									data-bs-slide-to="0"
									className="active"
									aria-current="true"
									aria-label="Slide 1"
								></button>
								<button
									type="button"
									data-bs-target="#carouselRoadmapIndicators"
									data-bs-slide-to="1"
									aria-label="Slide 2"
								></button>
								<button
									type="button"
									data-bs-target="#carouselRoadmapIndicators"
									data-bs-slide-to="2"
									aria-label="Slide 3"
								></button>
							</div>
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img src="images/roadmap-slides/1.png" className="d-block w-100" />
								</div>
								<div className="carousel-item">
									<img src="images/roadmap-slides/2.png" className="d-block w-100" />
								</div>
								<div className="carousel-item">
									<img src="images/roadmap-slides/rm-3.png" className="d-block w-100" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
